
export const createSearchResData = {
    createSearchRes(data_list) {
        if (data_list !== null) {
            let data = {}
            let assets = data_list
            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['name'] !== null)
                data['person_name'] = assets['person']['basic']['name']
            if (assets['creditBurn'] !== null)
                data['creditBurn'] = data_list['creditBurn']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['firstName'] !== null)
                data['person_first_name'] = assets['person']['basic']['firstName']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['lastName'] !== null)
                data['person_last_name'] = assets['person']['basic']['lastName']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['location'] !== null)
                data['person_location'] = assets['person']['basic']['location']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['jobTitle'] !== null)
                data['person_job_title'] = assets['person']['basic']['jobTitle']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['company'] !== null)
                data['person_company'] = assets['person']['basic']['company']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['linkedInUrl'] !== null)
                data['linked_in_url'] = assets['person']['basic']['linkedInUrl']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['extractedRole'] !== null)
                data['person_extracted_role'] = assets['person']['basic']['extractedRole']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['extractedSeniority'] !== null)
                data['person_extracted_seniority'] = assets['person']['basic']['extractedSeniority']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['extractedGender'] !== null)
                data['person_extracted_gender'] = assets['person']['basic']['extractedGender']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['dateDeNaissanceFormate'] !== null)
                data['person_date_of_birth'] = assets['person']['basic']['dateDeNaissanceFormate']

            if (assets['person'] !== null && assets['person']['basic'] !== null && assets['person']['basic']['age'] !== null)
                data['person_age'] = assets['person']['basic']['age']

            if (assets['person'] !== null && assets['person']['data'] !== null && assets['person']['data']['experience'] !== null && assets['person']['data']['experience'].length > 1) {
                if (assets['person']['data']['experience'][0] !== null && assets['person']['data']['experience'][0]['title'] !== null)
                    data['person_experience_title'] = assets['person']['data']['experience'][0]['title']

                if (assets['person']['data']['experience'][0] !== null && assets['person']['data']['experience'][0]['subtitle'] !== null)
                    data['person_experience_subtitle'] = assets['person']['data']['experience'][0]['subtitle']
            }


            if (assets['email'] !== null && assets['email']['email'] !== null)
                data['person_email'] = assets['email']['email']

            // harsh
            if (assets['company'] !== null && 'premium' in assets['company'] && assets['company']['premium'] && assets['company']['premium']['website'] !== null)
                data['company_detail_doamin_name'] = assets['company']['premium']['website']


            if (assets['company'] !== null)
                if ('full' in assets['company'] && assets['company']['full'] && 'properties' in assets['company']['full'] && assets['company']['full']['properties'] &&
                    assets['company']['full'] !== null && assets['company']['full']['properties'] !== null && assets['company']['full']['properties']['shortDescription'] !== null)
                    data['company_description'] = assets['company']['full']['properties']['shortDescription']
                else if (assets['company']['premium'] && assets['company']['premium']['slogan'] && assets['company']['premium']['slogan'] != '')
                    data['company_description'] = assets['company']['premium']['slogan']
            // else if (assets['company']['premium'] !== null && assets['company']['premium']['about'] !== null)
            //     data['company_description'] = assets['company']['premium']['about']

            if (assets['company'] !== null)
                if ('full' in assets['company'] && assets['company']['full'] && 'cards' in assets['company']['full'] && assets['company']['full']['cards'] &&
                    assets['company']['full'] !== null && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewFields'] !== null && assets['company']['full']['cards']['overviewFields']['foundedOn'] !== null && assets['company']['full']['cards']['overviewFields']['foundedOn']['value'] !== null)
                    data['company_yearfounded'] = assets['company']['full']['cards']['overviewFields']['foundedOn']['value']

                else if (assets['company']['premium'] !== null && assets['company']['premium']['founded'] !== null)
                    data['company_yearfounded'] = assets['company']['premium']['founded']

            if (assets['company'] !== null) {
                if ('french' in assets['company'] && assets['company']['french'])
                    if (assets['company'] !== null && assets['company']['french'] !== null && assets['company']['french']['siren'] !== null)
                        data['company_french_siren'] = assets['company']['french']['siren']
                if (assets['company'] !== null && assets['company']['french'] !== null && assets['company']['french']['siren'] !== null && assets['company']['french']['finances'] !== null)
                    data['company_finances_siren'] = assets['company']['french']['finances']
                if (assets['company'] !== null && assets['company']['french'] !== null && assets['company']['french']['codeNaf'] !== null)
                    data['company_french_codeNaf'] = assets['company']['french']['codeNaf']

                if (assets['company'] !== null && assets['company']['french'] !== null && assets['company']['french']['effectif'] !== null)
                    data['company_french_effectif'] = assets['company']['french']['effectif']

                if (assets['company'] !== null && assets['company']['french'] !== null && assets['company']['french']['dateCreationFormate'] !== null)
                    data['company_french_d_cre_form'] = assets['company']['french']['dateCreationFormate']

                if (assets['company'] !== null && assets['company']['french'] !== null && assets['company']['french']['numeroTvaIntracommunautaire'] !== null)
                    data['company_french_n_tva'] = assets['company']['french']['numeroTvaIntracommunautaire']
            }




            if (assets['company'] !== null)
                if ('full' in assets['company'] && assets['company']['full'] && 'cards' in assets['company']['full'] && assets['company']['full']['cards']) {
                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['fundingRoundsSummary'] !== null && 'valueUsdRoundup' && assets['company']['full']['cards']['fundingRoundsSummary']['fundingTotal'] && assets['company']['full']['cards']['fundingRoundsSummary']['fundingTotal']['valueUsdRoundup'] !== null)
                        data['company_funding_total_amount'] = assets['company']['full']['cards']['fundingRoundsSummary']['fundingTotal']['valueUsdRoundup']

                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['fundingRoundsSummary'] !== null && assets['company']['full']['cards']['fundingRoundsSummary']['lastFundingAt'] !== null)
                        data['company_last_Date'] = assets['company']['full']['cards']['fundingRoundsSummary']['lastFundingAt']

                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['fundingRoundsSummary'] !== null && assets['company']['full']['cards']['fundingRoundsSummary']['lastFundingType'] !== null)
                        data['company_last_fund_type'] = assets['company']['full']['cards']['fundingRoundsSummary']['lastFundingType']

                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['fundingRoundsSummary'] !== null && assets['company']['full']['cards']['fundingRoundsSummary']['numFundingRounds'] !== null)
                        data['company_num_funding_rounds'] = assets['company']['full']['cards']['fundingRoundsSummary']['numFundingRounds']

                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['fundingRoundsList'].length && assets['company']['full']['cards']['fundingRoundsList'][0] !== null && assets['company']['full']['cards']['fundingRoundsList'][0]['moneyRaised'] !== null && assets['company']['full']['cards']['fundingRoundsList'][0]['moneyRaised']['valueUsdRoundup'] !== null)
                        data['company__full_lst_round_amount'] = assets['company']['full']['cards']['fundingRoundsList'][0]['moneyRaised']['valueUsdRoundup']

                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewFields2'] !== null && assets['company']['full']['cards']['overviewFields2']['facebook'] !== null && assets['company']['full']['cards']['overviewFields2']['facebook']['value'] !== null)
                        data['company_face_url'] = assets['company']['full']['cards']['overviewFields2']['facebook']['value']

                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewFields2'] !== null && assets['company']['full']['cards']['overviewFields2']['twitter'] !== null && assets['company']['full']['cards']['overviewFields2']['twitter']['value'] !== null)
                        data['company_twitter_url'] = assets['company']['full']['cards']['overviewFields2']['twitter']['value']

                }

            //  if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewFields'] !== null && assets['company']['full']['cards']['overviewFields']['legalName'] !== null)
            //    data['company_leagal_name'] = assets['company']['full']['cards']['overviewFields']['legalName']




            if (assets['company'] !== null)
                if ('full' in assets['company'] && assets['company']['full'] && 'cards' in assets['company']['full'] && assets['company']['full']['cards']) {
                    if (assets['company']['full'] !== null && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewFields'] !== null && assets['company']['full']['cards']['overviewFields']['numEmployee'] !== null)
                        data['company_size'] = assets['company']['full']['cards']['overviewFields']['numEmployee']
                }
                else if (assets['company']['premium'] !== null && assets['company']['premium']['companySize'] !== null)
                    data['company_size'] = assets['company']['premium']['companySize']

            if (assets['company'] !== null)
                if ('full' in assets['company'] && assets['company']['full'] && 'cards' in assets['company']['full'] && assets['company']['full']['cards']) {
                    if (assets['company']['full'] !== null && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewCompanyFields'] !== null && assets['company']['full']['cards']['overviewCompanyFields']['companyType'] !== null)
                        data['company_type'] = assets['company']['full']['cards']['overviewCompanyFields']['companyType']
                }
                else if (assets['company']['premium'] !== null && assets['company']['premium']['type'] !== null)
                    data['company_type'] = assets['company']['premium']['type']

            if (assets['company'] !== null)
                if ('premium' in assets['company'] && assets['company']['premium'] !== null && assets['company']['premium']['url'] !== null)
                    data['company_linkedIn_url'] = assets['company']['premium']['url']

                else if ('full' in assets['company'] && assets['company']['full'] && 'cards' in assets['company']['full'] && assets['company']['full']['cards'])
                    if (assets['company'] !== null && 'full' in assets['company'] && assets['company']['full'] && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewFields2'] !== null && assets['company']['full']['cards']['overviewFields2']['linkedin'] !== null && assets['company']['full']['cards']['overviewFields2']['linkedin']['value'] !== null)
                        data['company_linkedIn_url'] = assets['company']['full']['cards']['overviewFields2']['linkedin']['value']


            if (assets['company'] !== null) {
                let fullCompanyData = []

                let premiumCompanyData = []
                if ('full' in assets['company'] && assets['company']['full'] && 'cards' in assets['company']['full'] && assets['company']['full']['cards'])
                    if (assets['company'] !== null && assets['company']['full'] !== null && assets['company']['full']['cards'] !== null && assets['company']['full']['cards']['overviewFields'] !== null && assets['company']['full']['cards']['overviewFields']['categories'].length)
                        fullCompanyData = assets['company']['full']['cards']['overviewFields']['categories']

                if (assets['company']['premium'] !== null && assets['company']['premium']['specialtiesArr'].length)
                    premiumCompanyData = assets['company']['premium']['specialtiesArr']

                if (premiumCompanyData.length && fullCompanyData.length) {
                    for (let index = 0; index < premiumCompanyData.length; index++) {
                        fullCompanyData.push(premiumCompanyData[index]);
                    }
                    data['company_detail_indust_cate'] = fullCompanyData
                }
                else if (premiumCompanyData.length)
                    data['company_detail_indust_cate'] = premiumCompanyData
                else { data['company_detail_indust_cate'] = fullCompanyData }


            }


            if (assets['company'] !== null) {
                if ('phone' in assets['company'] && assets['company']['phone']) {
                    if (assets['company'] !== null && assets['company']['phone'] !== null && assets['company']['phone']['formattedPhoneNumber'] !== null)
                        data['company_phone_format_number'] = assets['company']['phone']['formattedPhoneNumber']

                    if (assets['company'] !== null && assets['company']['phone'] !== null && assets['company']['phone']['formattedAddress'] !== null)
                        data['company_phone_format_address'] = assets['company']['phone']['formattedAddress']

                    if (assets['company'] !== null && assets['company']['phone'] !== null && assets['company']['phone']['geometry'] !== null)
                        data['company_phone_geometry'] = assets['company']['phone']['geometry']

                    if (assets['company'] !== null && assets['company']['phone'] !== null && assets['company']['phone']['vicinity'] !== null)
                        data['company_phone_vicinity'] = assets['company']['phone']['vicinity']

                    if (assets['company'] !== null && 'technologies' in assets['company'] && assets['company']['technologies'] && assets['company']['technologies'].length)
                        data['company_technolagies'] = assets['company']['technologies']

                    if (assets['company'] !== null && 'technologiesCategories' in assets['company'] && assets['company']['technologiesCategories'] && assets['company']['technologiesCategories'].length)
                        data['company_technolagies_categories'] = assets['company']['technologiesCategories']
                }

            }


            if (assets['company'] !== null) {
                if ('premium' in assets['company'] && assets['company']['premium']) {
                    if (assets['company'] !== null && assets['company']['premium'] !== null && assets['company']['premium']['industries'] !== null)
                        data['company_premium_industries'] = assets['company']['premium']['industries']

                    if (assets['company'] !== null && assets['company']['premium'] !== null && assets['company']['premium']['categories'].length !== null)
                        data['company_premium_categories'] = assets['company']['premium']['categories']

                    if (assets['company'] !== null && assets['company']['premium'] !== null && assets['company']['premium']['numberOfLocation'] !== null)
                        data['company_premium_numberOfLocation'] = assets['company']['premium']['numberOfLocation']

                    if (assets['company'] !== null && assets['company']['premium'] !== null && assets['company']['premium']['employeesAmountInLinkedin'] !== null)
                        data['company_premium_emplamountlink'] = assets['company']['premium']['employeesAmountInLinkedin']

                    if (assets['company'] !== null && assets['company']['premium'] !== null && assets['company']['premium']['headquaterAddr'] !== null)
                        data['company_premium_headquaterAddr'] = assets['company']['premium']['headquaterAddr']

                    if (assets['company'] !== null && 'info' in assets['company'] && assets['company']['info']['handle'] !== null && assets['company']['info']['handle']['crunchbase'] !== null && assets['company']['info']['handle']['crunchbase']['url'] !== null)
                        data['company_crunch_url'] = assets['company']['info']['handle']['crunchbase']['url']


                    if (assets['company'] !== null && 'info' in assets['company'] && assets['company']['info']['website'] !== null && assets['company']['info']['website']['trafficGlobalRank'] !== null)
                        data['company_website_traffic'] = assets['company']['info']['website']['trafficGlobalRank']

                    if (assets['company'] !== null && 'info' in assets['company'] && assets['company']['info']['contact'] !== null && assets['company']['info']['contact']['phoneNumber'] !== null)
                        data['company_phonenumber'] = assets['company']['info']['contact']['phoneNumber']
                }

            }


            return data
        }
    },
    createSearchCompany(data_list) {
        if (data_list !== null) {
            let data = {}
            let fullCompanyData = []
            let premiumCompanyData = []
            let assets = data_list['company']
            if (assets['creditBurn'] !== null)
                data['creditBurn'] = data_list['creditBurn']
            if (assets['premium'] !== null && assets['premium']['website'] !== null)
                data['company_detail_doamin_name'] = assets['premium']['website']


            if (assets['full'] !== null && 'properties' in assets['full'] && assets['full']['properties'] && assets['full']['properties'] !== null && assets['full']['properties']['shortDescription'] !== null)
                data['company_description'] = assets['full']['properties']['shortDescription']

            else if (assets['premium'] && assets['premium']['slogan'] && assets['premium']['slogan'] != '')
                data['company_description'] = assets['premium']['slogan']
            // else if (assets['premium'] !== null && assets['premium']['about'] !== null)
            //     data['company_description'] = assets['premium']['about']

            if (assets['full'] && 'cards' in assets['full'] && assets['full']['cards'] && assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['overviewFields'] !== null && assets['full']['cards']['overviewFields']['foundedOn'] !== null && assets['full']['cards']['overviewFields']['foundedOn']['value'] !== null)
                data['company_yearfounded'] = assets['full']['cards']['overviewFields']['foundedOn']['value']

            else if (assets['premium'] !== null && assets['premium']['founded'] !== null)
                data['company_yearfounded'] = assets['premium']['founded']

            if (assets['french'] !== null) {
                if (assets['french'] !== null && assets['french']['siren'] !== null)
                    data['company_french_siren'] = assets['french']['siren']
                if (assets['french'] !== null && assets['french']['finances'] !== null && assets['french']['siren'] !== null)
                    data['company_finances_siren'] = assets['french']['finances']

                if (assets['french'] !== null && assets['french']['codeNaf'] !== null)
                    data['company_french_codeNaf'] = assets['french']['codeNaf']

                if (assets['french'] !== null && assets['french']['effectif'] !== null)
                    data['company_french_effectif'] = assets['french']['effectif']

                if (assets['french'] !== null && assets['french']['dateCreationFormate'] !== null)
                    data['company_french_d_cre_form'] = assets['french']['dateCreationFormate']

                if (assets['french'] !== null && assets['french']['numeroTvaIntracommunautaire'] !== null)
                    data['company_french_n_tva'] = assets['french']['numeroTvaIntracommunautaire']

            }



            if (assets['full'] !== null && 'cards' in assets['full'] && assets['full']['cards']) {
                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['fundingRoundsSummary'] !== null && 'valueUsdRoundup' && assets['full']['cards']['fundingRoundsSummary']['fundingTotal'] && assets['full']['cards']['fundingRoundsSummary']['fundingTotal']['valueUsdRoundup'] !== null)
                    data['company_funding_total_amount'] = assets['full']['cards']['fundingRoundsSummary']['fundingTotal']['valueUsdRoundup']

                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['fundingRoundsSummary'] !== null && assets['full']['cards']['fundingRoundsSummary']['lastFundingAt'] !== null)
                    data['company_last_Date'] = assets['full']['cards']['fundingRoundsSummary']['lastFundingAt']

                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['fundingRoundsSummary'] !== null && assets['full']['cards']['fundingRoundsSummary']['lastFundingType'] !== null)
                    data['company_last_fund_type'] = assets['full']['cards']['fundingRoundsSummary']['lastFundingType']

                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['fundingRoundsSummary'] !== null && assets['full']['cards']['fundingRoundsSummary']['numFundingRounds'] !== null)
                    data['company_num_funding_rounds'] = assets['full']['cards']['fundingRoundsSummary']['numFundingRounds']

                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['fundingRoundsList'].length && assets['full']['cards']['fundingRoundsList'][0] !== null && assets['full']['cards']['fundingRoundsList'][0]['moneyRaised'] !== null && assets['full']['cards']['fundingRoundsList'][0]['moneyRaised']['valueUsdRoundup'] !== null)
                    data['company__full_lst_round_amount'] = assets['full']['cards']['fundingRoundsList'][0]['moneyRaised']['valueUsdRoundup']

                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['overviewFields2'] !== null && assets['full']['cards']['overviewFields2']['facebook'] !== null && assets['full']['cards']['overviewFields2']['facebook']['value'] !== null)
                    data['company_face_url'] = assets['full']['cards']['overviewFields2']['facebook']['value']

                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['overviewFields2'] !== null && assets['full']['cards']['overviewFields2']['twitter'] !== null && assets['full']['cards']['overviewFields2']['twitter']['value'] !== null)
                    data['company_twitter_url'] = assets['full']['cards']['overviewFields2']['twitter']['value']

            }

            // # if (assets['full'] && assets['full']['cards'] !== null && assets['full']['cards']['overviewFields'] !== null && assets['full']['cards']['overviewFields']['legalName'] !== null)
            // #     data['company_leagal_name'] = assets['full']['cards']['overviewFields']['legalName']




            if (assets['full'] !== null && 'cards' in assets['full'] && assets['full']['cards']) {
                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['overviewFields'] !== null && assets['full']['cards']['overviewFields']['numEmployee'] !== null)
                    data['company_size'] = assets['full']['cards']['overviewFields']['numEmployee']
            }

            else if (assets['premium'] !== null && assets['premium']['companySize'] !== null)
                data['company_size'] = assets['premium']['companySize']

            if (assets['full'] !== null && 'cards' in assets['full'] && assets['full']['cards']) {
                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['overviewCompanyFields'] !== null && assets['full']['cards']['overviewCompanyFields']['companyType'] !== null)
                    data['company_type'] = assets['full']['cards']['overviewCompanyFields']['companyType']
            }


            else if (assets['premium'] !== null && assets['premium']['type'] !== null)
                data['company_type'] = assets['premium']['type']

            // if (assets['premium'] !== null && assets['premium']['url'] !== null)
            //     data['company_linkedIn_url'] = assets['premium']['url']

            if (assets['full'] !== null && 'cards' in assets['full'] && assets['full']['cards'])
                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['overviewFields2'] !== null && assets['full']['cards']['overviewFields2']['linkedin'] !== null && assets['full']['cards']['overviewFields2']['linkedin']['value'] !== null)
                    data['company_linkedIn_url'] = assets['full']['cards']['overviewFields2']['linkedin']['value']


            if (assets['full'] !== null && 'cards' in assets['full'] && assets['full']['cards'] !== null)
                if (assets['full'] !== null && assets['full']['cards'] !== null && assets['full']['cards']['overviewFields'] !== null && assets['full']['cards']['overviewFields']['categories'].length)
                    fullCompanyData = assets['full']['cards']['overviewFields']['categories']

            if (assets['premium'] !== null && assets['premium']['specialtiesArr'].length)
                premiumCompanyData = assets['premium']['specialtiesArr']

            if (premiumCompanyData.length && fullCompanyData.length) {
                for (let index = 0; index < premiumCompanyData.length; index++) {
                    fullCompanyData.push(premiumCompanyData[index]);
                }
                // for (let premium in premiumCompanyData)
                //     fullCompanyData.push(premium)
                data['company_detail_indust_cate'] = fullCompanyData
            }

            else if (premiumCompanyData.length)
                data['company_detail_indust_cate'] = premiumCompanyData
            else {
                data['company_detail_indust_cate'] = fullCompanyData
            }



            if ('phone' in assets && assets['phone'] !== null)
                if (assets['phone'] !== null && assets['phone']['formattedPhoneNumber'] !== null)
                    data['company_phone_format_number'] = assets['phone']['formattedPhoneNumber']

            if (assets['phone'] !== null && assets['phone']['formattedAddress'] !== null)
                data['company_phone_format_address'] = assets['phone']['formattedAddress']

            if (assets['phone'] !== null && assets['phone']['geometry'] !== null)
                data['company_phone_geometry'] = assets['phone']['geometry']

            if (assets['phone'] !== null && assets['phone']['vicinity'] !== null)
                data['company_phone_vicinity'] = assets['phone']['vicinity']

            if ('technologies' in assets && assets['technologies'] && assets['technologies'].length)
                data['company_technolagies'] = assets['technologies']

            if ('technologiesCategories' in assets && assets['technologiesCategories'] && assets['technologiesCategories'].length)
                data['company_technolagies_categories'] = assets['technologiesCategories']

            if (assets['premium'] !== null)
                if (assets['premium'] !== null && assets['premium']['industries'] !== null)
                    data['company_premium_industries'] = assets['premium']['industries']

            if (assets['premium'] !== null && assets['premium']['categories'].length !== null)
                data['company_premium_categories'] = assets['premium']['categories']

            if (assets['premium'] !== null && assets['premium']['numberOfLocation'] !== null)
                data['company_premium_numberOfLocation'] = assets['premium']['numberOfLocation']

            if (assets['premium'] !== null && assets['premium']['employeesAmountInLinkedin'] !== null)
                data['company_premium_emplamountlink'] = assets['premium']['employeesAmountInLinkedin']

            if (assets['premium'] !== null && assets['premium']['headquaterAddr'] !== null)
                data['company_premium_headquaterAddr'] = assets['premium']['headquaterAddr']

            if ('info' in assets && assets['info']['handle'] !== null && assets['info']['handle']['crunchbase'] !== null && assets['info']['handle']['crunchbase']['url'] !== null)
                data['company_crunch_url'] = assets['info']['handle']['crunchbase']['url']



            if ('info' in assets && assets['info']['website'] !== null && assets['info']['website']['trafficGlobalRank'] !== null)
                data['company_website_traffic'] = assets['info']['website']['trafficGlobalRank']

            if ('info' in assets && assets['info']['contact'] !== null && assets['info']['contact']['phoneNumber'] !== null)
                data['company_phonenumber'] = assets['info']['contact']['phoneNumber']

            return data
        }

    }
}


