<template>
  <div class="demo-main">
    <div class="heading">
      <h2>Enrich API Demo</h2>
      <p class="light-text pt-2">
        Use this demo explorer to see how our API works You can replicate this
        massively with
        <RouterLink to="file-upload">File Upload</RouterLink>or integrate in
        your product with our <RouterLink to="user-api">API</RouterLink>
      </p>
    </div>
    <div class="demo-search sr pt-lg-40">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="search-bar">
            <div class="input-group mb-3" id="searchBar">
              <div class="input-group-prepend">
                <span><img src="@/assets/images/icons/searchbar.svg"/></span>
              </div>

              <input
                id="searchBar1"
                class="form-control search-demo-input form-input"
                placeholder="Full Name"
                type="text"
                v-model="user.searchValue1"
                name="searchValue1"
              />
              <div
                class="input-group-prepend"
                style="border-left:1px solid #dfe1e6;"
              >
                <span><img src="@/assets/images/icons/searchbar.svg"/></span>
              </div>
              <input
                id="searchBar2"
                class="form-control search-demo-input large-field-form"
                placeholder="Company name, Linkedin Url, Email,  or etc..."
                type="text"
                v-model="user.searchValue2"
                name="searchValue2"
              />
              <div
                class="input-group-prepend"
                style="border-left:1px solid #dfe1e6;"
              >
                <span><img src="@/assets/images/icons/searchbar.svg"/></span>
              </div>
              <v-select
                v-model="user.country"
                label="name"
                placeholder="Country"
                :components="{ Deselect }"
                :options="countryList"
                :reduce="(option) => option.alpha2"
                class="form-control search-demo-input  form-input"
              ></v-select>
              <div class="find-btn">
                <button
                  :disabled="!user.searchValue2"
                  class="btn input-group-text"
                  v-on:click="submitFrom"
                >
                  Find
                </button>
              </div>
            </div>
            <div class="try-example">
              <div class="try1">
                <span class="font-lg-12 weight-600 light-text"
                  >Try an example:</span
                >
                <span
                  class="primary-text font-lg-12 weight-600 light-bg pointer"
                  v-on:click="
                    submitFrom('https://www.linkedin.com/in/clementmoracin')
                  "
                  >https://www.linkedin.com/in/clementmoracin</span
                >
                <span
                  class="
                    primary-text
                    font-lg-12
                    weight-600
                    desktop-screen
                    light-bg
                    pointer
                  "
                  v-on:click="submitFrom('Tram Huynh')"
                  >Tram Huynh - Airbnb</span
                >
                <span
                  class="
                    primary-text
                    font-lg-12
                    weight-600
                    desktop-screen
                    light-bg
                    pointer
                  "
                  v-on:click="submitFrom('julien.lemoine@algolia.com')"
                  >julien.lemoine@algolia.com</span
                >
                <span
                  class="
                    primary-text
                    font-lg-12
                    weight-600
                    desktop-screen
                    light-bg
                    pointer
                  "
                  v-on:click="submitFrom('playplay.com')"
                  >playplay.com</span
                >
                <span
                  class="
                    primary-text
                    font-lg-12
                    weight-600
                    desktop-screen
                    light-bg
                    pointer
                  "
                  v-on:click="submitFrom('Dropbox')"
                  >Dropbox</span
                >
                <span
                  class="
                    primary-text
                    font-lg-12
                    weight-600
                    desktop-screen
                    light-bg
                    pointer
                  "
                  v-on:click="submitFrom('794693960')"
                  >794693960</span
                >
              </div>
            </div>
            <div class="select-box">
              <label style="margin-right: 50px">
                <input
                  type="radio"
                  class="radio-button"
                  v-model="person_data"
                  :value="person_data"
                />
                <span
                  class="checkbox-label secondary-text weight-400 font-lg-14"
                  style="margin-left: 10px"
                  >Person Data
                </span>
              </label>
              <label class="custom-checkbox" style="margin-right: 50px"
                ><span
                  class="checkbox-label secondary-text weight-400 font-lg-14"
                  >Basic Company Data
                </span>
                <input
                  type="checkbox"
                  v-model="premium_data"
                  :value="premium_data"
                  v-on:click="checkboxData('premium_data', $event.target.value)"
                />
                <span class="checkmark"></span>
              </label>
              <label class="custom-checkbox" style="margin-right: 50px"
                ><span
                  class="checkbox-label secondary-text weight-400 font-lg-14"
                  >Full Company Data
                </span>
                <input
                  type="checkbox"
                  v-model="financial_comp_data"
                  :value="financial_comp_data"
                  v-on:click="
                    checkboxData('financial_comp_data', $event.target.value)
                  "
                />
                <span class="checkmark"></span>
              </label>
              <label class="custom-checkbox" style="margin-right: 50px"
                ><span
                  class="checkbox-label secondary-text weight-400 font-lg-14"
                  >French Company Data
                </span>
                <input
                  type="checkbox"
                  v-model="french_company_data"
                  :value="french_company_data"
                  v-on:click="
                    checkboxData('french_company_data', $event.target.value)
                  "
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="searchData">
      <DemoResult
        v-bind:userdata="userData"
        v-bind:emailRes="email"
        @updateUserData="update"
        @updateFinancialCompData="updateFinancialCompDataSubmit"
        @updateFrenchCompanyData="updateFrenchCompanyDataSubmit"
        @findUserEmail="findUserEmailData"
      ></DemoResult>
    </div>

    <div class="demo-search-next" v-if="!searchData">
      <div class="demo-social">
        <div class="facebook-card">
          <skeleton-loader-vue
            type="gradient"
            :width="293"
            :height="20"
            animation="fade"
          />
        </div>
        <br />
        <div class="facebook-card-new">
          <skeleton-loader-vue
            type="gradient"
            :width="200"
            :height="20"
            animation="fade"
          />
        </div>
      </div>

      <div class="demo-content">
        <h2>Demo Search Result</h2>
        <p>
          To get started please enter email, company or linkedin<br />
          profile link to the search input
        </p>
      </div>

      <div class="new-loader">
        <div class="facebook-card-main">
          <skeleton-loader-vue
            type="gradient"
            :width="1186"
            :height="20"
            animation="fade"
          />
        </div>

        <div class="facebook-card-main">
          <skeleton-loader-vue
            type="gradient"
            :width="1186"
            :height="20"
            animation="fade"
          />
        </div>

        <div class="facebook-card-main">
          <skeleton-loader-vue
            type="gradient"
            :width="1186"
            :height="20"
            animation="fade"
          />
        </div>

        <div class="facebook-card-main">
          <skeleton-loader-vue
            type="gradient"
            :width="1186"
            :height="20"
            animation="fade"
          />
        </div>
        <div class="facebook-card-main">
          <skeleton-loader-vue
            type="gradient"
            :width="1186"
            :height="20"
            animation="fade"
          />
        </div>
        <div class="facebook-card-main">
          <skeleton-loader-vue
            type="gradient"
            :width="1186"
            :height="20"
            animation="fade"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { countryList } from "../../../store/constant/intergration.type.js";
import {
  GET_EMAIL_DATA,
  ID_TOKEN_KEY,
  USER_EMAIL,
  GET_USER_CREDIT,
  // USER_CREDIT,
} from "../../../store/constant/actions.type";
import { Setuser_credit } from "../../common/services/getUserCredit.service";
import { createSearchResData } from "../../common/services/assets.service";
import $ from "jquery";
import Vue from "vue";
import axios from "axios";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import { loadingService } from "../../common/services/loading.service";
import VueSkeletonLoader from "skeleton-loader-vue";
import checkAuthTokenValidity from "../../common/services/auth.service.js";
export default {
  name: "userdemo",
  components: {
    DemoResult: () => import("../../common/demo-result/demo-result.vue"),
    "skeleton-loader-vue": VueSkeletonLoader,
  },
  data() {
    return {
      email: {},
      fullPage: false,
      countryList: countryList,
      Deselect: {
        render: (createElement) => createElement("span", ""),
      },
      formPayload: {
        country: null,
        start: 0,
        num: 10,
      },
      loader: "bars",
      searchData: false,
      person_data: true,
      premium_data: true,
      financial_comp_data: false,
      french_company_data: false,
      person_full: false,
      fullApi: false,
      userData: {},
      user: {
        searchValue: null,
        searchValue1: null,
        searchValue2: null,
        country: null,
      },
      submitted: false,
      markers: [],
      places: [],
    };
  },
  methods: {
    update(userData) {
      this.person_full = userData;
      this.submitFrom();
    },
    updateFinancialCompDataSubmit() {
      this.financial_comp_data = true;
      this.submitFrom();
    },
    updateFrenchCompanyDataSubmit() {
      this.french_company_data = true;
      this.submitFrom();
    },
    findUserEmailData(userData) {
      let key = localStorage.getItem("$s");
      const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) };
      axios
        .get(
          `https://gateway.datagma.net/api/ingress/v6/findEmail?apiId=${key}&source=demo_enrich_page&fullName=${userData?.person_name}&company=${userData?.person_company}&findEmailV2Step=3&findEmailV2Country=${userData?.person_location}`,
          // `https://gateway.datagma.info/api/ingress/v4/findEmail?firstName=${userData?.person_first_name}&lastName=${userData?.person_company}
          // &company=${userData?.person_company}&findEmailV2Step=3&findEmailV2Country=${userData?.person_location}&apiId=${key}`,
          { headers }
        )
        .then(({ data }) => {
          this.$eventBus.$emit("callCreditMethod");
          if (data && data.email) {
            this.email = data;
          }
        })
        .catch((error) => {
          // Handle errors, such as 401, using your error handling service
          checkAuthTokenValidity(error);
          // Additional error handling logic if needed
        });
    },

    submitFrom(value = null, target = null) {
      this.email = {};
      loadingService.setloader(true);
      this.submitted = true;
      this.searchData = false;
      if (this.user.searchValue1 && this.user.searchValue2) {
        this.user.searchValue =
          this.user.searchValue2 + "&fullName=" + this.user.searchValue1;
        this.fullApi = true;
      } else if (this.user.searchValue2) {
        this.user.searchValue = this.user.searchValue2;
      } else if (this.user.searchValue1) {
        this.user.searchValue = this.user.searchValue1;
      }
      if (this.user.country)
        this.user.searchValue =
          this.user.searchValue + "&countryCode=" + this.user.country;
      let chackValue;
      if (value && value.target && value.target.value == "")
        chackValue = this.user.searchValue;
      else if (value && typeof value == "string") chackValue = value;
      else chackValue = this.user.searchValue;
      if (!this.validations(chackValue)) {
        loadingService.setloader(false);
        return;
      }
      this.createPayload(value, target);
      if (this.user.searchValue) {
        let payload = {
          user_search_data: this.user.searchValue,
          companyFull: this.financial_comp_data,
          companyFrench: this.french_company_data,
          companyPremium: this.premium_data,
          personFull: this.person_full,
          full_Api: this.fullApi,
        };
        this.$store
          .dispatch(GET_EMAIL_DATA, payload)
          .then((response) => {
            this.$eventBus.$emit("callCreditMethod");
            if (response) {
              if (this.fullApi) {
                response = createSearchResData.createSearchRes(response);
              } else {
                response = createSearchResData.createSearchCompany(response);
              }
              Setuser_credit();
              this.submitted = true;
              response["search_data"] = this.user.searchValue;
              response["person_full"] = this.person_full ? true : false;
              response["financial_comp_data"] = this.financial_comp_data
                ? true
                : false;
              response["french_company_data"] = this.french_company_data
                ? true
                : false;
              this.searchData = true;
              this.setUserMapData(response);
            }
          })
          .catch((err) => {
            this.submitted = true;
            if (err && err.data && err.data.code) {
              if (
                err.data.code == 3 &&
                err.data.message == "This email is not accepted"
              ) {
                loadingService.setloader(false);
                this.flashMessage.error({
                  title: "Error",
                  message: "We don’t enrich personal email",
                });
              } else {
                loadingService.setloader(false);
                this.flashMessage.error({
                  title: "Error",
                  message: err.data.message,
                });
              }
            }
          });
      } else {
        loadingService.setloader(false);
        this.flashMessage.error({
          title: "Error",
          message: "your data entry is wrong  ",
        });
        this.user.searchValue = "";
        this.user.searchValue1 = "";
        this.user.searchValue2 = "";
      }
    },
    setUserMapData(data) {
      if (data && data.company_phone_geometry) {
        const marker = {
          lat: data.company_phone_geometry.location.lat,
          lng: data.company_phone_geometry.location.lng,
        };
        this.markers.push({ position: marker });

        this.places.push(data.company_phone_geometry);
        data["markers"] = this.markers;
        data["places"] = this.places;
      }
      data["fullApi"] = this.fullApi;
      this.userData = data;
      loadingService.setloader(false);
    },
    checkboxData(value = null, target = null) {
      if (value && target !== undefined) {
        target = String(target) == "true" ? true : false;
        switch (value) {
          case "premium_data":
            this.premium_data = !target;
            break;
          case "financial_comp_data":
            this.financial_comp_data = !target;
            break;
          case "french_company_data":
            this.french_company_data = !target;
            break;
          case "person_full":
            this.person_full = !target;
            break;
          default:
            this.premium_data = true;
            this.financial_comp_data = false;
            this.french_company_data = false;
            this.person_full = true;
        }
      }
    },
    createPayload(value = null, target = null) {
      loadingService.setloader(true);
      if (value && target !== undefined) {
        target = String(target) == "true" ? true : false;
        switch (value) {
          case "Tram Huynh":
            this.user["searchValue"] = value;
            this.user["searchValue1"] = value;
            this.user["searchValue2"] = null;
            break;
          case "https://www.linkedin.com/in/clementmoracin":
            this.user["searchValue"] = value;
            this.user["searchValue1"] = null;
            this.user["searchValue2"] = value;
            this.fullApi = true;
            break;
          case "julien.lemoine@algolia.com":
            this.user["searchValue"] = value;
            this.user["searchValue1"] = null;
            this.user["searchValue2"] = value;
            this.fullApi = true;
            break;
          case "playplay.com":
            this.user["searchValue"] = value;
            this.user["searchValue1"] = null;
            this.user["searchValue2"] = value;
            this.fullApi = false;
            break;
          case "Dropbox":
            this.user["searchValue"] = value;
            this.user["searchValue1"] = null;
            this.user["searchValue2"] = value;
            this.fullApi = false;
            break;
          case "794693960":
            this.user["searchValue"] = value;
            this.user["searchValue1"] = null;
            this.user["searchValue2"] = value;
            this.fullApi = false;
            break;
        }
      }
    },
    validations(search) {
      if (search && search !== null && search !== undefined) {
        if (
          search.indexOf("@") != -1 &&
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g.test(
            search
          )
        ) {
          this.fullApi = true;
          return true;
        } else if (
          search.indexOf("linkedin.com") != -1 &&
          /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(
            search
          )
        ) {
          this.fullApi = true;
          return true;
        } else if (
          search.indexOf("linkedin.com") == -1 &&
          /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})/gm.test(
            search
          ) &&
          !parseInt(search)
        ) {
          this.fullApi = false;
          return true;
        } else if (/^(?:\d{9}|\d{14})/gm.test(parseInt(search))) {
          this.fullApi = false;
          return true;
        } else if (
          search.indexOf("@") == -1 &&
          search.indexOf("linkedin.com") == -1 &&
          !parseInt(search) &&
          search !== "Tram Huynh" &&
          !(this.user.searchValue1 && this.user.searchValue2)
        ) {
          this.fullApi = false;
          return true;
        } else if (
          search.indexOf("@") == -1 &&
          search.indexOf("linkedin.com") == -1 &&
          !parseInt(search) &&
          search == "Tram Huynh" &&
          !(this.user.searchValue1 && this.user.searchValue2)
        ) {
          this.fullApi = true;
          return true;
        } else if (this.user.searchValue1 && this.user.searchValue2) {
          this.fullApi = true;
          return true;
        } else {
          return false;
        }
      }
    },
    getUserCardit() {
      if (
        localStorage.getItem(USER_EMAIL) &&
        localStorage.getItem(USER_EMAIL) !== undefined &&
        localStorage.getItem(ID_TOKEN_KEY) &&
        localStorage.getItem(ID_TOKEN_KEY) !== undefined &&
        localStorage.getItem("$s") &&
        localStorage.getItem("$s") !== undefined
      ) {
        let payload = {
          apiId: localStorage.getItem("$s"),
          email: localStorage.getItem(USER_EMAIL),
        };
        this.$store
          .dispatch(GET_USER_CREDIT, payload)
          .then((data) => {
            if (data && data.country) {
              this.user.country = data.country;
            }
          })
          .catch((err) => {
            this.flashMessage.error({
              title: "Error Message",
              message: err.message,
            });
          });
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  mounted() {
    $(function() {
      var inptxt = $(".search-demo-input");
      $(inptxt)
        .on("focusin", function() {
          $(".input-group").addClass("focus-active");
        })
        .on("focusout", function() {
          $(".input-group").removeClass("focus-active");
        });
    });
    this.getUserCardit();
  },

  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
  },
};
</script>
<style scoped>
.demo-search.sr .search-bar ::v-deep .input-group input {
  height: auto;
}
.vs--searchable ::v-deep .vs__dropdown-toggle {
  border: unset;
}
.demo-search.sr .input-group input {
  background: rgb(0 0 0 / 0%) !important;
}
.pointer {
  cursor: pointer;
}
.demo-result-main {
  padding-top: 64px;
}
.demo-main {
  padding: 0 15px;
}
.search-bar {
  position: relative;
}
.search-bar .input-group,
.search-bar .input-group input {
  height: 72px;
}
.search-bar .input-group {
  background: #f7f8fa;
  border: 1px solid #dfe1e6;
  position: relative;
  border-radius: 8px;
}
.focus-active:after {
  content: "";
  position: absolute;
  border: 1px solid #2589ff;
  width: 100%;
  height: 74px;
  border-radius: 8px;
  top: -2px;
}

.search-bar .form-control.search-demo-input:focus {
  border: none;
  transition: none;
}
.search-bar input {
  border-left: none;
  border-right: none;
  margin-top: -1px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  transition: none;
}
.input-new {
  width: 20%;
}
.input-new-second {
  width: 27%;
}
.input-new-third {
  width: 30%;
}
.input-new-second input#searchBar {
  border-left: 1px solid #e0e0e0;
  border-radius: 1px;
}
.input-new-third input#searchBar {
  border-left: 1px solid #e0e0e0;
  border-radius: 1px;
}

.search-bar .input-group-prepend {
  width: 6%;
  align-items: center;
  justify-content: center;
}
.input-group-text {
  background: #2589ff;
  color: white;
  padding: 20px 40px;
  margin: 13px 20px;
  height: 41px;
  border-radius: 9px;
  width: 81px;
  justify-content: center;
  border: none;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
}
.try-example {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.try-example .try1 span {
  padding: 10px;
  font-weight: 600;
  display: inline-block;
  margin: 0 7px;
}
.try2 span {
  padding-left: 22px;
  font-weight: 600;
}

.demo-search-result {
  padding-top: 100px;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.select-box {
  padding-top: 30px;
}
.input-group {
  width: 97%;
}
input.form-control.search-demo-input.first {
  border-left: 1px solid #e0e0e0;
}
input.form-control.search-demo-input.secund {
  border-left: 1px solid #e0e0e0;
}
.demo-content {
  text-align: center;
}
.demo-search-next {
  position: relative;
}
.demo-social {
  position: absolute;
  top: 128px;
  right: 0;
  left: 9%;
}
.demo-content h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 48px;
  font-weight: 500;
  color: #003172;
}
.demo-content p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7a869a;
  margin-top: 17px;
}
.loader {
  border-radius: 23px !important;
}
.facebook-card-new .loader {
  width: 227px !important;
  margin-top: -13px;
}
.demo-search-next {
  position: relative;
  width: 97%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(32 32 35 / 4%), 0px 0px 2px rgb(32 32 35 / 6%),
    0px 0px 1px rgb(32 32 35 / 4%);
  border-radius: 8px;
  /* height: 343px; */
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.facebook-card-main {
  display: flex;
  justify-content: center;
  padding-bottom: 19px;
}
@media screen and (min-width: 1500px) {
  .large-field-form {
    width: 440px;
  }
}

@media screen and (min-width: 577px) {
  .large-field-form {
    width: 330px;
  }
}

@media only screen and (max-width: 576px) {
  .search-bar .input-group,
  .search-bar .input-group input {
    height: 60px;
  }
  .input-group-text {
    margin: 8px 10px;
  }
  .search-bar .input-group-prepend {
    width: 50px;
  }
  .demo-result-main {
    padding-top: 24px;
  }
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1;
}

.light-text a {
  color: black;
  font-weight: 600;
  margin-left: 2px;
  margin-right: 2px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
